<template>
  <div class="expande-horizontal wrap centraliza">
    <v-flex :key="plan._id" v-for="plan in plans" xs12 md3 class="pa-2">
      <PlanItem :plan="plan" />
    </v-flex>
  </div>
</template>

<script>
import PlanItem from "./PlanItem.vue";
export default {
  components: { PlanItem },
  data() {
    return {
      plans: [],
      loading: false
    };
  },
  methods: {
    get_plans() {
      this.loading = true;
      this.$run("plans/list")
        .then(res => {
          this.plans = res.data.docs;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  created() {
    this.get_plans();
  }
};
</script>
