<template>
  <v-flex xs12>
    <div
      class="card-plan pa-6 pb-0 fonte"
      :class="
        plan.name.toLowerCase() === 'smart' ? 'pt-12 pb-12 card-plan-smart' : ''
      "
    >
      <LottieAnimation
        ref="anim"
        v-if="plan.name === 'trial'"
        :loop="true"
        :animationData="require('@/apps/shared/assets/trial_plan.json')"
      />

      <LottieAnimation
        ref="anim2"
        :loop="true"
        v-if="plan.name.toLowerCase() === 'lite'"
        style="scale: 0.8; position: relative; top: -50px;"
        :animationData="require('@/apps/shared/assets/bronze_plan.json')"
      />

      <LottieAnimation
        ref="anim3"
        :loop="true"
        v-if="plan.name.toLowerCase() === 'smart'"
        style="scale: 0.8; position: relative; top: -50px;"
        :animationData="require('@/apps/shared/assets/trial_plan.json')"
      />

      <LottieAnimation
        ref="anim4"
        :loop="true"
        v-if="plan.name.toLowerCase() === 'pro'"
        style="scale: 0.8; position: relative; top: -50px;"
        :animationData="require('@/apps/shared/assets/gold_plan.json')"
      />

      <div
        style="position: relative; top: -70px;"
        class="expande-horizontal column centraliza wrap"
      >
        <h3 class="text-capitalize font-weight-light">Plano {{ plan.name }}</h3>
        <h2 class="fonte">
          {{ $helper.formataSaldo(plan.value_cents / 1000) }}
        </h2>

        <span class="fonte font-weight-bold fonte-micro mt-6 mb-0">
          {{ recurrencyType }}
        </span>

        <div class="expande-horizontal centraliza column pb-9">
          <div
            v-for="(feat, index) in plan.features"
            :key="`feat-${index}`"
            class="expande-horizontal centraliza"
          >
            <v-icon size="18" class="mr-1" :color="$theme.secondary"
              >mdi-check</v-icon
            >
            <span class="fonte text-center" style="color: #555;">
              {{ feat.name }}
            </span>
          </div>
        </div>
        <v-btn
          v-if="plan.name.toLowerCase() === 'smart'"
          dark
          @click="openCheckout(plan._id)"
          class="fonte"
          block
          rounded
          x-large
          :color="$theme.secondary"
        >
          Assinar
        </v-btn>
        <v-btn
          @click="openCheckout(plan._id)"
          v-else
          rounded
          dark
          class="fonte"
          block
          :color="$theme.primary"
        >
          Assinar
        </v-btn>
      </div>
      <!-- {{ plan }} -->
    </div>
  </v-flex>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  components: { LottieAnimation },
  props: {
    plan: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  computed: {
    recurrencyType() {
      const types = {
        months: "Mensal"
      };
      return types[this.plan.interval_type];
    }
  },
  methods: {
    openCheckout(plan_id) {
      this.$router.push(`/checkout?p_id=${plan_id}`);
      location.reload();
    }
  }
};
</script>

<style>
.card-plan {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 6px;
  flex-direction: column;
  border: 1px solid #e1673c;
}
.card-plan-smart {
  border: 3px solid #010f50;
}
</style>
