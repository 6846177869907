<template>
  <layout title="Assinar" :options="[]">
    <v-flex xs12>
      <v-window v-model="stage">
        <v-window-item :value="1" class="pa-3"> <Plans /> </v-window-item>

        <v-window-item :value="2" class="pa-3"> <Checkout /> </v-window-item>

        <v-window-item :value="2" class="pa-3">
          <PayWithPix />
          <PayWithCard />
          <PayWithCard />
        </v-window-item>
      </v-window>
    </v-flex>
  </layout>
</template>

<script>
import Checkout from "../components/Checkout.vue";
import Plans from "../components/Plans.vue";
export default {
  data() {
    return {
      stage: 1,
      card: {
        number: "",
        verification_value: "",
        expiration: "",
        full_name: ""
      }
    };
  },
  methods: {
    createPaymentToken() {
      window.Iugu.createPaymentToken(this.card, res => {
        if (res.errors) {
          console.log("erro ao criar cartão");
        } else {
          console.log("token criado", res.id);
        }
      });
    }
  },
  created() {
    if (this.$route.query.p_id) {
      this.stage = 2;
    }
    window.Iugu.setAccountID(process.env.VUE_APP_ID_ACCOUNT_IUGU);
    window.Iugu.setTestMode(true);
    window.Iugu.setup();
  },
  components: { Checkout, Plans }
};
</script>
