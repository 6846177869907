<template>
  <div
    class="expande-horizontal pt-6 pb-5 centraliza wrap"
    style="align-items: flex-start;"
  >
    <v-flex xs12 md3>
      <v-flex xs12>
        <span class="fonte">1. Plano Escolhido:</span>
        <div
          style="border: 2px solid #e0e0e0; border-radius: 1px;"
          class="card-seleted_plan centraliza column pa-6 pl-0 pb-0 fonte"
          :class="
            seleted_plan.name.toLowerCase() === 'smart'
              ? 'pt-12 pb-12 card-seleted_plan-smart'
              : ''
          "
        >
          <div class="expande-horizontal centraliza">
            <LottieAnimation
              ref="anim"
              v-if="seleted_plan.name.toLowerCase() === 'trial'"
              :loop="true"
              :animationData="require('@/apps/shared/assets/trial_plan.json')"
            />

            <LottieAnimation
              ref="anim2"
              :loop="true"
              v-if="seleted_plan.name.toLowerCase() === 'lite'"
              style="scale: 1; width: 40px; height: 40px; position: relative;"
              :animationData="require('@/apps/shared/assets/bronze_plan.json')"
            />

            <LottieAnimation
              ref="anim3"
              :loop="true"
              v-if="seleted_plan.name.toLowerCase() === 'smart'"
              style="scale: 0.8; position: relative; top: -50px;"
              :animationData="require('@/apps/shared/assets/trial_plan.json')"
            />

            <LottieAnimation
              ref="anim4"
              :loop="true"
              v-if="seleted_plan.name.toLowerCase() === 'pro'"
              style="scale: 0.8; position: relative; top: -50px;"
              :animationData="require('@/apps/shared/assets/gold_plan.json')"
            />
          </div>

          <div
            style="position: relative;"
            class="expande-horizontal column centraliza wrap"
          >
            <h3 class="text-capitalize font-weight-light">
              {{ seleted_plan.name }}
            </h3>
            <h2 style="color: #010f50" class="fonte">
              {{ $helper.formataSaldo(seleted_plan.value_cents / 1000) }}
            </h2>
          </div>
          <!-- {{ seleted_plan }} -->
        </div>
      </v-flex>
    </v-flex>
    <v-flex class="pl-2 pa-3 pt-0" xs12 md9>
      <span class="fonte">
        2. Escolhe como deseja pagar e aproveite os benefícios do seu plano
      </span>
      <div
        style="min-height: 30vh; border-radius: 1px; border: 2px solid #e5e5e5; "
        class="expande-horizontal pt-6 centraliza wrap"
      >
        <v-flex class="pr-3" xs12 md3>
          <v-card
            hover
            height="150"
            @click="selectPaymentMethod('pix')"
            color="white"
            class="pt-7"
          >
            <div
              style="min-height: 150px;"
              class=" pt-6 pb-6 expande-horizontal centraliza column"
            >
              <img width="100" src="@/apps/shared/assets/pix.png" alt="pix" />
              <h4 class="fonte pt-7">Pix</h4>
            </div>
          </v-card>
        </v-flex>
        <v-flex class="pr-3" xs12 md3>
          <v-card
            hover
            height="150"
            @click="selectPaymentMethod('card')"
            color="white"
          >
            <div class=" pt-6 pb-6 expande-horizontal centraliza column">
              <!-- <img width="100" src="@/apps/shared/assets/card.gif" alt="card" /> -->
              <h4 class="fonte">Cartão de crédito</h4>
            </div>
          </v-card>
        </v-flex>
        <v-flex class="pr-3" xs12 md3>
          <v-card
            hover
            height="150"
            @click="selectPaymentMethod('boleto')"
            color="white"
          >
            <div class=" pt-6 pb-6 expande-horizontal centraliza column">
              <img
                width="100"
                src="@/apps/shared/assets/barcode.gif"
                alt="card"
              />
              <h4 class="fonte">Boleto</h4>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <div
            style="min-height: 20vh;"
            v-if="payment_method === 'pix'"
            class="expande-horizontal centraliza"
          >
            <h2>Pix</h2>
          </div>
          <div
            style="min-height: 20vh;"
            v-if="payment_method === 'card'"
            class="expande-horizontal centraliza"
          >
            <h2>Cartão</h2>
          </div>
          <div
            style="min-height: 20vh;"
            v-if="payment_method === 'boleto'"
            class="expande-horizontal centraliza"
          >
            <h2>Boleto</h2>
          </div>
        </v-flex>
        <v-flex v-if="payment_method" class="py-6" xs12>
          <div class="expande-horizontal centraliza">
            <v-btn
              color="#010f50"
              dark
              class="text-capitalize mt-3 fonte"
              @click="goToCheckout"
            >
              Avançar para pagamento
            </v-btn>
          </div>
        </v-flex>
      </div>
    </v-flex>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation
  },
  data() {
    return {
      seleted_plan: {
        value_cents: 0,
        name: "..."
      },
      plans: [],
      payment_method: ""
    };
  },
  methods: {
    checkPlan() {
      this.seleted_plan = this.plans.find(
        plan => plan._id.toLowerCase() === this.$route.query.p_id
      );
    },
    get_plans() {
      this.loading = true;
      this.$run("plans/list")
        .then(res => {
          this.plans = res.data.docs;
          this.checkPlan();
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    selectPaymentMethod(method) {
      this.payment_method = method;
    }
  },
  created() {
    this.get_plans();
  }
};
</script>
